import { useEffect, useState } from "react";
import {
	BrowserRouter,
	HashRouter,
	useNavigate,
	useRoutes,
} from "react-router-dom";
import Routes from "./routes/Routes";
import { useAuth } from "./hooks/useAuth";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { getAnalytics } from "firebase/analytics";
import auth from "./services/auth";
import { ThemeProvider } from "./components/theme-provider";
import { MissionContext } from "./hooks/useMission";
import { DialogProvider } from "./components/dialog-context";
// import { Web3ModalProvider } from "./components/wallet-context";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
const projectId = "c0973f2b6020eb8492c58e4115852c02";
import { Toaster } from "@/components/ui/toaster";
import NotMobile from "./NotMobileView";
import "./cloudMessage";
import { toast } from "./hooks/use-toast";
import socket from "./services/socket";

// 2. Set chains
const mainnet = {
	chainId: 1,
	name: "Ethereum",
	currency: "ETH",
	explorerUrl: "https://etherscan.io",
	rpcUrl: "https://cloudflare-eth.com",
};

// 3. Create a metadata object
const metadata = {
	name: "Supercharge",
	description: "Supercharge",
	url: "https://superchar.xyz.com", // origin must match your domain & subdomain
	icons: [
		"https://images.squarespace-cdn.com/content/v1/65b5c45d10fa0e6aff9b8d38/462fed56-3a6f-4e00-9a0c-c5b3584ea960/Group+6.png?format=1500w",
	],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
	/*Required*/
	metadata,

	/*Optional*/
	enableEIP6963: true, // true by default
	enableInjected: true, // true by default
	enableCoinbase: true, // true by default
	rpcUrl: "...", // used for the Coinbase SDK
	defaultChainId: 1, // used for the Coinbase SDK
});

// 5. Create a AppKit instance
createWeb3Modal({
	ethersConfig,
	chains: [mainnet],
	projectId,
	enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

const firebaseConfig = {
	apiKey: "AIzaSyDOfd7I2dzCqeILkMBx2HNk-Nuql7fPUxY",
	authDomain: "supercharge-32b48.firebaseapp.com",
	projectId: "supercharge-32b48",
	storageBucket: "supercharge-32b48.firebasestorage.app",
	messagingSenderId: "612475495350",
	appId: "1:612475495350:web:5b6a25833007ed22779717",
	measurementId: "G-F8DW78BDQJ",
};

function App() {
	// if (window.innerWidth > 640) {
	// 	return <NotMobile />;
	// }
	const { user } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		const app = initializeApp(firebaseConfig);
		socket.on("haveMessage", (data) => {
			console.log(data);
			toast({
				title: data.user_name + " sent you a message",
				description: data.content,
				onClick: () => {
					navigate("/user/chat/" + data.conversation_id);
				},
			});
		});

		// const messaging = getMessaging(app);
		// onMessage(messaging, (payload) => {
		// 	console.log("Message received. ", payload);
		// 	// ...
		// });

		// getToken(messaging, {
		// 	vapidKey:
		// 		"BFtY9vviUYrzSlNlpmPDBmATFw6-Iitn_N4w6GLy1AOvX31IgAF5ccTkdkqg4VhzwjE5L1Adz3JPqybS-luETj0",
		// })
		// 	.then((currentToken) => {
		// 		if (currentToken) {
		// 			console.log("FCM token", currentToken);
		// 			// Send the token to your server and update the UI if necessary
		// 			// ...
		// 		} else {
		// 			// Show permission request UI
		// 			console.log(
		// 				"No registration token available. Request permission to generate one."
		// 			);
		// 			// ...
		// 		}
		// 	})
		// 	.catch((err) => {
		// 		console.log("An error occurred while retrieving token. ", err);
		// 		// ...
		// 	});
	}, []);

	if (user) {
		auth.setAuthorizationToken(user?.sctoken);
	}

	let routing = useRoutes(Routes.getRoutes());

	return (
		<div className="flex items-center justify-center h-screen">
			<main className="block md:hidden w-full h-full">
				<Toaster />
				{/* <Web3ModalProvider> */}
				<DialogProvider>
					<ThemeProvider
						defaultTheme="system"
						storageKey="spc-ui-theme">
						<MissionContext.Provider value={{}}>
							{routing}
						</MissionContext.Provider>
						<Toaster />
					</ThemeProvider>
				</DialogProvider>
				{/* </Web3ModalProvider> */}
			</main>
			{/* Other Device View */}
			<div className="w-full bg-foreground text-background flex flex-col items-center justify-center h-full p-16 hidden md:flex">
				<NotMobile />
			</div>
		</div>
	);
}

export default App;
